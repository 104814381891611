import { Link } from "gatsby"
import React, { useEffect } from "react"
import DoctorSelect from "../components/DoctorSelect"
import FacilitiesCounts from "../components/home/FacilitiesCounts"
import HighlightSection from "../components/home/HighlightSection"
import WhyChooseUs from "../components/home/WhyChooseUs"
import Seo from "../components/Seo"
import SuperspecialityDoctorsTable from "../components/SuperspecialityDoctorsTable"
import withLayout from "../layout/withLayout"

const IndexPage = withLayout(({ siteData }) => {
  useEffect(() => {
    const { $ } = window
    // Home Slides
    $(".home-slides").owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      autoplayHoverPause: true,
      autoplay: true,
      items: 1,
      animateOut: "fadeOut",
      navText: [
        "<i class='flaticon-back'></i>",
        "<i class='flaticon-next-1'></i>",
      ],
    })
    $(".home-slides").on("translate.owl.carousel", function () {
      // $(".main-banner-content .sub-title")
      //   .removeClass("animated fadeInLeft")
      //   .css("opacity", "0")
      // $(".main-banner-content h1")
      //   .removeClass("animated fadeInLeft")
      //   .css("opacity", "0")
      // $(".main-banner-content p")
      //   .removeClass("animated fadeInUp")
      //   .css("opacity", "0")
      // $(".main-banner-content .btn-box .btn")
      //   .removeClass("animated fadeIn")
      //   .css("opacity", "0")
      // $(".main-banner-content .btn-box .video-btn")
      //   .removeClass("animated fadeIn")
      //   .css("opacity", "0")
    })
    $(".home-slides").on("translated.owl.carousel", function () {
      // $(".main-banner-content .sub-title")
      //   .addClass("animated fadeInLeft")
      //   .css("opacity", "1")
      // $(".main-banner-content h1")
      //   .addClass("animated fadeInLeft")
      //   .css("opacity", "1")
      // $(".main-banner-content p")
      //   .addClass("animated fadeInUp")
      //   .css("opacity", "1")
      // $(".main-banner-content .btn-box .btn")
      //   .addClass("animated fadeIn")
      //   .css("opacity", "1")
      // $(".main-banner-content .btn-box .video-btn")
      //   .addClass("animated fadeIn")
      //   .css("opacity", "1")
    })
  }, [])
  return (
    <>
      <Seo title="Nemcare Hospital" siteMetadata={siteData} />
      <div className="home-slides owl-carousel owl-theme">
        {/* <div className="main-banner item-bg2">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <h1>COVID 19 Care Center at Tezpur</h1>
                  <p>
                    We are committed to uncovering and fighting for the best and most efficient ways to deliver high-quality care for COVID 19 patients under separate roof.
                  </p>

                  <div className="btn-box">
                    <a href="/covid" className="btn btn-primary">
                      KNOW MORE <i className="flaticon-next"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="main-banner item-bg1">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="main-banner-content">
                  <span className="sub-title">Nemcare Hospital Tezpur</span>
                  <h1>Modern and Latest Technology Treatment</h1>
                  <p>
                    A wide array of highly specialised diagnostic and
                    therapeutic services. Full fledged diagnostic laboratory
                    with the most modern technology.
                  </p>
                  {/* <div className="btn-box">
                    <a href="#" className="btn btn-primary">
                      Make Appointment <i className="flaticon-next"></i>
                    </a>

                    <a
                      href="https://www.youtube.com/watch?v=bk7McNUjWgw"
                      className="video-btn popup-youtube"
                    >
                      Watch Now{" "}
                      <span>
                        <i className="flaticon-play-button"></i>
                      </span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="free-consultation-area">
        <div className="container d-flex justify-content-center">
          <div className="free-consultation-content text-center d-flex justify-content-between align-items-center">
            <img width={60} src="/img/plus.svg" />
            <div>
              <h2
                className="sub-title text-white"
                style={{ fontSize: "50px", fontWeight: "normal" }}
              >
                EMERGENCY
              </h2>
              <h2 className="text-white">Call {siteData.emergency_contact}</h2>
            </div>
            <img width={60} src="/img/plus.svg" />
          </div>
        </div>
        {/* <div className="container">
          <div className="free-consultation-content text-center">
            <h2
              className="sub-title"
              style={{ fontSize: 30, fontWeight: "normal" }}
            >
              EMERGENCY
            </h2>
            <h2>Call +91 7035 022 110</h2>

            <p className="sub-title" style={{ fontSize: 16 }}>
              Radiology, Surgery, Medicine, Dentistry, Eye Care, Oncology,
              Obstetrics and Gynaecology
            </p>
          </div>
        </div> */}
      </section>

      <section style={{ marginTop: 30 }}>
        <div className="container">
          <div className="row">
            {/* <div className="col-12 col-md-4 col-lg-3 nemcare-institutes">
              <i class="large-icon flaticon-doctor"></i>
              <h4>The Nemcare Group</h4>
              <a href="https://nemcare.com/" target="blank" rel="noopener noreferer">View More</a>
            </div> */}
            <div className="col-12 col-md-4 col-lg-3 nemcare-institutes">
              <i class="large-icon flaticon-doctor"></i>
              <h4>Nemcare Super Speciality</h4>
              <a
                href="https://nemcare.com/"
                target="blank"
                rel="noopener noreferer"
              >
                View More
              </a>
            </div>
            <div className="col-12 col-md-4 col-lg-3 nemcare-institutes">
              <i class="large-icon flaticon-doctor"></i>
              <h4>Nemcare Hospital Guwahati</h4>
              <a
                href="https://nemcarehospital.in/"
                target="blank"
                rel="noopener noreferer"
              >
                View More
              </a>
            </div>
            <div className="col-12 col-md-4 col-lg-3 nemcare-institutes">
              <i class="large-icon flaticon-doctor"></i>
              <h4>Nemcare Group of Institutions</h4>
              <a
                href="https://ngiguwahati.in/"
                target="blank"
                rel="noopener noreferer"
              >
                View More
              </a>
            </div>
            <div className="col-12 col-md-4 col-lg-3 nemcare-institutes">
              <i class="large-icon flaticon-doctor"></i>
              <h4>Burn Care Foundation</h4>
              <a
                href="http://burncarefoundation.org.in/"
                target="blank"
                rel="noopener noreferer"
              >
                View More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-info" role="alert">
                <string>Annual Return 2021 - 2022</string>{" "}
                <a
                  target={"_blank"}
                  rel="noopener noreferer"
                  href="https://storage.googleapis.com/itechcom_public/nemcaretezpur/Annual-return_2021-2022-2.pdf"
                >
                  Download PDF File
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-info" role="alert">
                For Any Information regarding{" "}
                <string>Annual General Meeting 2021</string> visit{" "}
                <Link to="/general_meetings">General Meetings Page</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <SuperspecialityDoctorsTable /> */}

      {/* <!-- Start About Area --> */}
      <section className="about-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <img src="/img/about-img1.jpg" alt="image" />
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span className="sub-title">Nemcare Hospital</span>
                <h2>Quality and Cost Effective Healthcare Facilities</h2>
                <p>
                  NEMCARE Hospital Tezpur Pvt Ltd has been offering the best
                  possible tertiary healthcare services in the entire Sonitpur
                  District. Nemcare has been a favourite household name in terms
                  of quality, affordability and creating a platform for utmost
                  homespun and familiar ambience for patients since June, 2020.
                </p>
                <p>
                  Our Mission is to provide compassionate, accessible, high
                  quality, cost effective healthcare to the community and to
                  promote health.
                </p>
                <p>
                  Our Vision is to be a prominent regional leader known for
                  meeting the healthcare needs through excellent clinical
                  quality, accessible patient-centered caring service and
                  unmatched healthcare commitment.
                </p>
                <p>
                  Our Team of Consultants is backed by well-trained and
                  experienced Physicians, Surgeons, Anesthesiologists &amp;
                  Emergency Medical Officers. World-class healthcare with a
                  human touch is what NEMCARE is committed to and shall always
                  strive to keep improving upon its service standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Start FunFacts Area --> */}
      <FacilitiesCounts />
      {/* <!-- End FunFacts Area --> */}

      {/* <!-- Start Services Area --> */}
      <HighlightSection />
      {/* <!-- End Services Area --> */}

      {/* <!-- Start Why Choose Us Area --> */}
      <WhyChooseUs />

      <section className="doctor-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Our Partners</span>
            <h2>Empanelled Companies &amp; TPA</h2>
            <h2>Date: 21.06.2022</h2>
          </div>

          <div className="row">
            <div className="col-12">
              <table className="description_table mt-4">
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        fontWeight: "bold",
                        color: "#fff",
                        background: "rgb(254, 35, 91)",
                        borderColor: "rgb(254, 35, 91)",
                      }}
                    >
                      Empanelled Insurance Companies
                    </td>
                    {/* <td>Discount</td> */}
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Star Health & Allied Insurance Co. Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Aditya Birla Health Insurance Co. Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Bajaj Allianz General Insurance Company Limited</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Reliance General Insurance Company Limited</td>
                    {/* <td>10%</td> */}
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Future General India Insurance Company Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Care Health Insurance Limited.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Niva Bupa Health Insurance Company Limited.</td>
                    {/* <td>5%</td> */}
                  </tr>

                  <tr>
                    <td>8</td>
                    <td>SBI General Insurance Company Limited</td>
                    {/* <td>5%</td> */}
                  </tr>

                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        fontWeight: "bold",
                        color: "#fff",
                        background: "rgb(254, 35, 91)",
                        borderColor: "rgb(254, 35, 91)",
                      }}
                    >
                      Empanelled TPAs
                    </td>
                    {/* <td>5%</td> */}
                  </tr>

                  <tr>
                    <td>1</td>
                    <td>MedSave Health Insurance TPA Limited</td>
                    {/* <td>5%</td> */}
                  </tr>

                  <tr>
                    <td>2</td>
                    <td>MD India Health Insurance TPA Pvt. Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>FHPL (Family Health Plan TPA Ltd).</td>
                    {/* <td>5%</td> */}
                  </tr>

                  <tr>
                    <td>4</td>
                    <td>Medi Assist Insurance TPA Pvt. Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Safe Way TPA Pvt. Ltd.</td>
                    {/* <td>5%</td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section className="doctor-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">Our Doctors</span>
            <h2>Our Team of Experianced Doctors at your Service</h2>
            <p>
              The team of doctors at Nemcare Hospital Tezpur is well experianced
              and always ready to provide you with utmost care during emergency
              and trauma.
            </p>
          </div>

          <div style={{ maxWidth: 600, margin: "40px auto" }}>
            <DoctorSelect />
          </div>

          <div
            className="d-flex justify-content-center"
            style={{ margin: "40px auto" }}
          >
            <a href="/team" className="btn btn-primary">
              View All Doctors <i className="flaticon-next"></i>
            </a>
          </div>
        </div>
      </section>
    </>
  )
})

export default IndexPage
